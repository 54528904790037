import React from "react";
import './search.scss';

const Search = ({onSearch, onEnter, abilityToType}) => {

  function returnTag(data) {
    if (data.keyCode === 13) {
      onEnter(data.target.value)
      data.target.value = ''
      data.target.blur()
    }
  }

  return (
    <div className="search-container">
      <input className='search-bar'
        type="text"
        placeholder="Type to Search or [Enter] to Create Tag"
        onChange={(e) => abilityToType ? onSearch(e.target.value) : null}
        onKeyDown={ (e) => returnTag(e)}
      />
    </div>
  );
};

export default Search;
