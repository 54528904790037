import React, { useState, useEffect, useRef } from "react";
import {
  LoadingIndicator,
  Icon,
  PopoverMenu,
  IconButton,
  PopoverMenuContent,
  PopoverMenuToggleButton,
  PopoverMenuItem,
  Tooltip,
} from "cfa-react-components";
import NavigationBar from "../../components/HomeNavBar/Nav";
import Search from "../../components/SearchBar/Search";
import { Tag, Typography } from "cfa-react-components";
import FilterEditor from "../../components/FilterBar/FilterEditor";
import "./HomePage.scss";
import Table from "../../components/Table/Table";
import Maps from "../../components/Map/Maps";
import ColumnEditor from "../../components/ColumnEditor/ColumnEditor";

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

const HomePage = () => {
  const [input, setInput] = useState("");
  const [numDisplay, setNumDisplay] = useState(5);
  const [tagsArray, setTagsArray] = useState([]);
  const [numResults, setNumResults] = useState();
  const [filtersObj, setFiltersObj] = useState(null);
  const [keysList, setKeysList] = useState([]);
  const [displayTable, setDisplayTable] = useState([]);
  const [filteredActiveCategories, setFilteredActiveCategories] = useState({});
  const [jsonStuff, setJsonStuff] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [reRender, setReRender] = useState(false);
  const [abilityToType, setAbilityToType] = useState(true);
  const [clickedMarker, setClickedMarker] = useState({});
  const [activeCats, setActiveCats] = useState([
    "locationNumber",
    "locationName",
    "physicalAddress",
    "operatorName",
    "locationStatus",
    "tempCloseFlag",
    "locationFormatType",
    "timeZoneName",
  ]);
  const locations_url = process.env.REACT_APP_LOCATION_API_GET_LOCATIONS;

  function downloadCSV(data, filename, allColumns) {
    const csv = allColumns
      ? convertAllToCSV(data, "locationName")
      : convertVisibleToCSV(data, "locationName");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function formatColumnNames(cols) {
    let temp = [];
    cols.forEach((element) => {
      element = element.split(/(?=[A-Z])/);
      element[0] = element[0][0].toUpperCase() + element[0].substring(1);
      element = element.join(" ");
      temp.push(element);
    });
    return temp;
  }

  function convertAllToCSV(data, firstColumn) {
    const columns = keysList.filter((key) => key !== "remodelHistory");
    const firstIndex = columns.indexOf(firstColumn);
    if (firstIndex !== -1 && firstIndex !== 0) {
      columns.splice(firstIndex, 1);
      columns.unshift(firstColumn);
    }
    const sortedData = data.slice().sort((a, b) => {
      const aValue = a[firstColumn];
      const bValue = b[firstColumn];
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    });
    const rows = sortedData.map((obj) => {
      const values = columns.map((key) => {
        let value = obj[key];
        if (typeof value === "string" && value.indexOf(",") !== -1) {
          value = `"${value}"`;
        }
        return value;
      });
      return values.join(",");
    });
    return formatColumnNames(columns).join(",") + "\n" + rows.join("\n");
  }

  function convertVisibleToCSV(data, firstColumn) {
    const columns = activeCats.filter((key) => key !== "remodelHistory");
    // const formattedColumns = formatColumnNames(columns)
    const firstIndex = columns.indexOf(firstColumn);
    if (firstIndex !== -1 && firstIndex !== 0) {
      columns.splice(firstIndex, 1);
      columns.unshift(firstColumn);
    }
    const sortedData = data.slice().sort((a, b) => {
      const aValue = a[firstColumn];
      const bValue = b[firstColumn];
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    });
    const rows = sortedData.map((obj) => {
      const values = columns.map((key) => {
        let value = obj[key];
        if (typeof value === "string" && value.indexOf(",") !== -1) {
          value = `"${value}"`;
        }
        return value;
      });
      return values.join(",");
    });
    return formatColumnNames(columns).join(",") + "\n" + rows.join("\n");
  }

  function handleLocationsResponse(locations) {
    setJsonStuff({
      data: locations,
    });
    let keyList = [];
    for (var entry in locations) {
      for (var key in locations[entry]) {
        if (!keyList.includes(key)) {
          keyList.push(key);
        }
      }
    }
    setKeysList(keyList.sort());
    setDisplayTable(locations);
    setNumResults(locations.length);
    setIsLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      const token = getToken();
      let increment = 500;

      await fetch(process.env.REACT_APP_LOCATION_API_GET_INCREMENT, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + token,
        }),
      }).then((data) =>
        data.json().then((returnData) => {
          increment = parseInt(returnData["message"]);
        })
      );

      var urls = [];
      var promises = [];

      for (let i = 0; i <= 6; i++) {
        urls.push(
          process.env.REACT_APP_LOCATION_API_GET_LOCATIONS +
            String(increment * i)
        );
      }

      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        promises.push(
          fetch(url, {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + token,
            }),
          })
        );
      }

      let resultsSoFar = [];

      for (const promise of promises) {
        const response = await promise;
        await response.json().then((res) => {
          if (res?.data) {
            resultsSoFar = resultsSoFar.concat(res.data);
            handleLocationsResponse(resultsSoFar);
          }
        });
      }
    }

    fetchData();
  }, []);

  function handleClickedMarker(clickedMarkerParam) {
    if (clickedMarkerParam != clickedMarker) {
      setClickedMarker(clickedMarkerParam);
    } else {
      document
        .getElementsByName("Map")[0]
        .scrollIntoView({ behavior: "smooth" });
    }
  }

  useDidMountEffect(
    (e) => {
      document
        .getElementsByName("Map")[0]
        .scrollIntoView({ behavior: "smooth" });
    },
    [clickedMarker]
  );

  function getToken() {
    const oktaCookie = localStorage.getItem("okta-token-storage");
    let accessToken = "";

    if (oktaCookie) {
      const token = JSON.parse(oktaCookie);
      accessToken = token.accessToken.accessToken;
    }

    return accessToken;
  }

  function getResultNum(data) {
    setNumResults(data);
  }

  function keyDownHandler(data) {
    if (data.trim() !== "") {
      setTagsArray([...tagsArray, data]);
    }
  }

  useDidMountEffect(
    (e) => {
      setInput("");
      setAbilityToType(false);
    },
    [tagsArray]
  );

  function inputHandler(e) {
    if (parseInt(e.target.value) < 0 || isNaN(parseInt(e.target.value))) {
      e.target.value = 0;
    }

    if (parseInt(e.target.value) > numResults) {
      e.target.value = numResults;
    }

    e.target.value = parseInt(e.target.value);
    setNumDisplay(parseInt(e.target.value));
  }

  function handleTagClose(tagIndex) {
    // remove Tag from array
    const newTags = [...tagsArray];
    newTags.splice(tagIndex, 1);
    setTagsArray(newTags);
  }

  return (
    <>
      <div className="home-page">
        <NavigationBar />
        <div className="body-div">
          <div className="SearchAndTags">
            <div className="SpaceDiv"></div>
            <div className="SearchAndResults">
              <div className="SearchContainer">
                <Search
                  style={{ alignSelf: "flex-end" }}
                  onSearch={setInput}
                  onEnter={(e) => keyDownHandler(e)}
                  abilityToType={abilityToType}
                />
                <div style={{ width: "2%" }} />
                <div
                  style={{
                    width: "90px",
                    display: tagsArray.length == 0 ? "inline" : "none",
                  }}
                />
                <Typography
                  variant="overline3"
                  style={{
                    width: "95px",
                    alignSelf: "flex-end",
                    display: tagsArray.length == 0 ? "none" : "inline",
                    marginBottom: "0.5%",
                  }}
                >
                  Searching By:
                </Typography>
                <div className="search-tags">
                  {tagsArray.map((tagText, index) => (
                    <Tag
                      color="secondaryblue"
                      variant="outlined"
                      key={tagText}
                      label={tagText}
                      onClose={() => handleTagClose(index)}
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "3px",
                        marginBottom: "2%",
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="results-and-col-editor">
                <div className="results-container">
                  Showing
                  <select
                    id="numResultsList"
                    style={{
                      margin: "0 0.5rem",
                    }}
                    onChange={(e) => inputHandler(e)}
                    defaultValue={numDisplay}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                  </select>
                  {/* <input  type="number" min='0' name="name"  defaultValue={numDisplay} style={{marginLeft: '1%', marginRight: '1%', width: '15%', textAlign: 'center'}} onChange={e => inputHandler(e)}></input> */}
                  of{" "}
                  {numResults ? (
                    numResults
                  ) : (
                    <LoadingIndicator size="sm" variant="inline" />
                  )}{" "}
                  results
                </div>
                <div className="column-editor">
                  <Tooltip
                    content="Scroll To Map"
                    showOnElementEvents={["hover"]}
                  >
                    <IconButton
                      style={{
                        width: "fit-content",
                        alignSelf: "center",
                        color: "#002F43",
                        padding: "3px",
                        margin: "5px",
                      }}
                      onClick={(e) =>
                        document
                          .getElementsByName("Map")[0]
                          .scrollIntoView({ behavior: "smooth" })
                      }
                      color="default"
                      size="md"
                    >
                      <div className="pin-icon">
                        <Icon icon="pin-outline" />
                      </div>
                    </IconButton>
                  </Tooltip>
                  <ColumnEditor
                    categories={keysList}
                    sendActiveCats={setActiveCats}
                  />
                  <div>
                    <PopoverMenu maxHeight="216px">
                      <Tooltip
                        content="Download Table as CSV"
                        showOnElementEvents={["hover"]}
                      >
                        <PopoverMenuToggleButton
                          as={IconButton}
                          style={{
                            width: "fit-content",
                            alignSelf: "center",
                            color: "#002F43",
                            padding: "3px",
                            margin: "5px",
                          }}
                        >
                          <div className="download-icon">
                            <Icon size="md" icon="download" />
                          </div>
                        </PopoverMenuToggleButton>
                      </Tooltip>

                      <PopoverMenuContent>
                        <PopoverMenuItem
                          id="downloadAll"
                          onClick={(e) =>
                            downloadCSV(
                              displayTable,
                              "Table Data (" + new Date().toUTCString() + ")",
                              true
                            )
                          }
                        >
                          <Icon icon="download" />
                          All Data
                        </PopoverMenuItem>
                        <PopoverMenuItem
                          id="downloadVisible"
                          onClick={(e) =>
                            downloadCSV(
                              displayTable,
                              "Table Data (" + new Date().toUTCString() + ")",
                              false
                            )
                          }
                        >
                          <Icon icon="download" />
                          Displayed Columns Only
                        </PopoverMenuItem>
                      </PopoverMenuContent>
                    </PopoverMenu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-and-table">
            <div className="filter-container">
              <FilterEditor
                filteredActiveCategories={filteredActiveCategories}
                filterDataHandler={setFiltersObj}
              />
            </div>
            <div className="TableContainer">
              {isLoading ? (
                <div className="loading-page">
                  <LoadingIndicator size="lg" variant="page" />
                </div>
              ) : (
                <>
                  <Table
                    setFilterCats={setFilteredActiveCategories}
                    filterData={filtersObj}
                    keysList={keysList}
                    numDisplay={numDisplay}
                    numResults={numResults}
                    jsonData={jsonStuff}
                    tags={tagsArray}
                    searchData={input}
                    onTableChange={(e) => getResultNum(e)}
                    sendDisplayTable={setDisplayTable}
                    returnAbilityToType={setAbilityToType}
                    activeCats={activeCats}
                    setClickedMarker={(e) => handleClickedMarker(e)}
                    name="displayTable"
                  />
                  <div name="Map" className="map">
                    <Maps
                      clickedMarker={clickedMarker}
                      mapPins={displayTable}
                      numResults={numResults}
                    />
                    <em style={{ marginTop: "0.5rem" }}>
                      Showing{" "}
                      {numResults > 800
                        ? "first 800 entries"
                        : `all ${numResults} entries`}
                    </em>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div>© 2023 CHICK-FIL-A INC. ALL RIGHTS RESERVED</div>
      </footer>
    </>
  );
};

export default HomePage;
