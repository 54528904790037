import dayjs from 'dayjs';

export const formatDate = (date) => {
  return dayjs(date).format('MM-D-YY');
}

export const formatReverseDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
}

export const formatDateWithFullYear = (date) => {
  return dayjs(date).format('MM-D-YYYY');
}

export const checkSameDay = (date) => {
  return dayjs().isSame(date, 'day');
}

export const formatDateAndTime = (date) => {
  return dayjs(date).format('M-D-YY, h:MM A');
}

export const getDateToday = () => {
  return dayjs().startOf('day').format('YYYY-MM-DD');
}

export const getLastWeekDate = () => {
  // Return string format for date-range widget
  return dayjs().startOf('day').subtract(1, 'week')['$d'];
}