import React from "react";
import { Logo } from "cfa-react-components/dist/cjs";
import "./Nav.scss"

const Navbar = () => {
  return (
    <header className = "nav-container"> 
    <div style={{width: '20vw'}}>
    <Logo
        style={{flex: 1}}
        color="primary"
        logo="script"
        size="lg"
      />
    </div>
      <div className="header-style">
        Location Viewer
      </div>
      <div  style={{width: '20vw'}}></div>
    </header>

  );
};



export default Navbar;