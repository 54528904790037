import React, { useState, useEffect, useRef } from "react";
import "./Table.scss";
import { Icon, Tooltip} from 'cfa-react-components';
import makeAnimated from 'react-select/animated';
import { Tooltips } from './Tooltips'

//This is copied from google, it is used to detect when the user
// clicks outside of a component. It is used to hide the chevrons when the user clicks off
const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
};

// This is a custom hook that functions identically to a UseEffect() except it only occurs after the inital rendering of the component
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

const TableHeaderItem = ({columnKey, sortColumnFunction, columnName}) => {

  const [columnSortState, setColumnSortState] = useState(1)
  const [isActive, setIsActive] = useState(1)

  const deactivateChevrons = () => {
    setIsActive(1)
  };

  const activateChevrons = () => {
    setIsActive(0)
  };

  useEffect( () => {
    if (columnKey == 'locationNumber') {
      handleColumnSort()
      setIsActive(0)
    }
  }, [])

  const ref = useOutsideClick(deactivateChevrons);

  function handleColumnSort() {
    setColumnSortState(columnSortState == 0 ? 1 : 0)
    sortColumnFunction(columnKey, columnSortState)
  }

  return (
    <th
      id={columnKey}
      className="table-header-field"
      ref={ref}
      onClick={activateChevrons}
    >
      <div className="table-header-field-contents">
        <Tooltip
          content={Tooltips[columnName]}
          showOnElementEvents={[
            'hover'
          ]}
        >
        <p
          className='pointer-cursor'
          onClick={(e) => handleColumnSort()}
        >
          {columnName}&nbsp;
        </p>
        </Tooltip>

        <Icon
          icon="chevron-down"
          size="xs"
          style={{alignSelf: 'center', justifySelf: 'center', cursor: "pointer", display: isActive == 0 ?  columnSortState == 0 ? 'none' : 'inline': 'none'}}
          onClick={(e) => handleColumnSort()}
        />
        <Icon
          icon="chevron-up"
          size="xs"
          style={{alignSelf: 'center', justifySelf: 'center', cursor: "pointer", display: isActive == 0 ?  columnSortState == 0 ? 'inline' : 'none ': 'none'}}
          onClick={(e) => handleColumnSort()}
        />
      </div>
    </th>
  );
};


const TableHeader = ({formattedActiveCats, activeCats, displayTable, handleChange, handleSelectedColumns}) => {
    const [sortTable, setSortTable] = useState(displayTable)
    const [sortArray, setSortArray] = useState(displayTable['data'])
    const myRef = React.createRef();
    const [selectedCats, setSelectedCats] = useState(activeCats)
    const [selectedFormattedCats, setSelectedFormattedCats] = useState(formattedActiveCats)
    const animatedComponents = makeAnimated();

    useDidMountEffect(() => {
        handleChange(sortTable)
    }, [sortTable])

    // Updates the column headers when the user adds or subtracts new columns
    useDidMountEffect(() => {
      formatCats()
      handleSelectedColumns(selectedCats)
  }, [selectedCats])

    // Keeps our copy of the table up to date with what is actually being displayed
    useDidMountEffect(() => {
      setSortTable(displayTable)
      setSortArray(displayTable['data'])
  }, [displayTable])

  useEffect(() => {
    formatCats()
  }, [])

  // Formats categories for React-Select
  function formatCats() {
    let temp = []
    selectedCats.forEach((element) => {
      let tempObj = {}
      tempObj['value'] = element
      element = element.split(/(?=[A-Z])/);
      element[0] = element[0][0].toUpperCase() + element[0].substring(1)
      element = element.join(' ')
      tempObj['label'] = element
      temp.push(tempObj)
    })
    setSelectedFormattedCats(temp)
  }

      // Handles the sorting of columns either forwards or reverse
      function sortColumn(columnKey, columnSortState) {
        if (columnSortState == 1) {
          setSortTable(sortTable => ({ 'data' : sortTable["data"].sort(function (a, b) {
            if (a[columnKey]) {
              return a[columnKey].localeCompare(b[columnKey]);
            }
            })}));
        } else {
          setSortTable(sortTable => ({ 'data' : sortTable["data"].sort(function (a, b) {
            if (a[columnKey]) {
              return a[columnKey].localeCompare(b[columnKey]);
            }
            }).reverse()}))
        }
      }

      // Makes the column names look pretty (columnName becomes Column Name)
      function formatName(name) {
        let element = name.split(/(?=[A-Z])/);
        element[0] = element[0][0].toUpperCase() + element[0].substring(1)
        element = element.join(' ')
        element = element.replace(/Address(\d+)/g, "Line $1");
        element = element.replace(/Phone Phone/g, "Phone");
        element = element.replace(/Ss/g, "SS");
        element = element.replace(/Pos/g, "POS");
        element = element.replace(/Guid/g, "GUID");
        element = element.replace(/Id/g, "ID");
        element = element.replace(/Rd/g, "RD");
        element = element.replace(/Dc/g, "DC");
        element = element.replace(/Erqa/g, "ERQA");
        element = element.replace(/Ldp/g, "LDP");
        element = element.replace(/Gmt/g, "GMT");
        return element
      }


  return (
      <thead>
        <tr className="table-header-section"><th style={{minWidth: '6%', maxWidth: '6%'}}/>
            {activeCats.map((prop) => (
              <TableHeaderItem key={prop} columnKey={prop} columnName={formatName(prop)} sortColumnFunction={sortColumn}></TableHeaderItem>
            ))}</tr>
      </thead>
  )
}


export default TableHeader
