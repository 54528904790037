import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  LoadingIndicator,
  Divider
} from "cfa-react-components";


const useDidMountEffect = (func, deps) => {

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};


const MapModal = ({locationToDisplay, closeModal, showModal}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [unFormattedResponse, setUnformattedResponse] = useState([]);

  function open() {
    setShow(true);
  }

  useDidMountEffect(() => {
    if (showModal) {
      handleModalText();
    }
  }, [showModal]);

  useDidMountEffect(() => {
      setIsLoading(true);
  }, [locationToDisplay]);

  function close() {
    setShow(false);
    closeModal(false)
  }

  function handleModalText() {
    open();
    getInfo(locationToDisplay["locationNumber"]);
  }

  function getToken() {
    const oktaCookie = localStorage.getItem("okta-token-storage");
    let accessToken = "";

    if (oktaCookie) {
      const token = JSON.parse(oktaCookie);
      accessToken = token.accessToken.accessToken;
    }

    return accessToken;
  }

  function getInfo(storeNum) {
    const token = getToken()
    fetch(process.env.REACT_APP_LOCATION_API_GET_SPECIFIC_STORE + storeNum, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      })
    }).then((res) => res.json().then((data) => {
      setUnformattedResponse(data['locationList'][0])
      setIsLoading(false)
    }))
}

  function renderBoldWords() {
    let reg = /\".*\":/g;
    let outerTabReg = / {18}/g;
    let innerTabReg = /- {6}/g;
    let whiteSpaceAndHyphens = /^\s*-\s*$/gm;


    let formatString = JSON.stringify(unFormattedResponse,null,6)//.replace(reg, '<b>' + reg + '</b>')

    formatString = formatString.replace(/[[]]+/g, 'none');
    formatString = formatString.replace(/[{}]+/g, '');
    formatString = formatString.replace(/[,]+/g, '');
    formatString = formatString.replace(outerTabReg, "             - ");
    formatString = formatString.replace(innerTabReg, "   - ");
    formatString = formatString.replace(whiteSpaceAndHyphens, '');


    let matchString = formatString.match(reg)
    for (var entry in matchString) {
        let regAll = new RegExp(matchString[entry], 'g')
        formatString = formatString.replaceAll(regAll, '<b>' + removeCamelCase(matchString[entry]) + '</b>')
    }

    formatString =  formatString.replaceAll(/['"]+/g, '')
    formatString = formatString.replaceAll('Core', 'Core Information')
    formatString = formatString.replaceAll('Address1', 'Address Line 1')
    formatString = formatString.replaceAll('Address2', 'Address Line 2')
    formatString = formatString.replaceAll('false', 'No')
    formatString = formatString.replaceAll('true', 'Yes')



    let n = formatString.toLowerCase().indexOf('location number')
    formatString = '<span name="general-info" style="font-size:23px; font-weight:1000; text-decoration:underline;">' + "\n<b style='text-align:center;'>\t\t\tGeneral Information</b>\n\n" + '</span><b>'+formatString.substring(n);

    n = formatString.toLowerCase().indexOf('location staff roles')
    formatString = formatString.substring(0,n)+ '<span name="staff-roles" style="font-size:23px; font-weight:1000; text-decoration:underline;">' + "\n\t\t\tSupport Staff\n\n" + '</span>'+formatString.substring(n);

    n = formatString.toLowerCase().indexOf('remodel history')
    formatString = formatString.substring(0,n)+'<span name="location-specifics" style="font-size:23px; font-weight:1000; text-decoration:underline;">' + "\n\t\t\tLocation Specifics\n\n" + '</span>'+formatString.substring(n);

    return {__html: formatString};
}

function removeCamelCase(str) {
  str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
  str = str.charAt(1).toUpperCase() + str.slice(2);
  return str;
}


  return (
    <>
      <Modal
        onClose={(e) => close()}
        onExit={(e) => close()}
        onExited={(e) => close()}
        onExiting={function noRefCheck() {}}
        size="lg"
        scrollMode="page"
        show={showModal}
      >
        <React.Fragment key=".0">
        <div className="my-modal-header">
          <ModalHeader style={{color: '#004F71'}}>
            Detailed Information for {locationToDisplay["locationName"]}
          </ModalHeader>
        </div>
        <ModalBody style={{padding:'0%'}}>
              <div className="modal-body">
                  <div className="info-menu">
                      <h3 className='nav-bar-modal-title'>SECTIONS</h3>
                      <a style={{ cursor: "pointer" }} className='nav-bar-modal' onClick={e => (document.getElementsByName('general-info')[0]).scrollIntoView({behavior: 'smooth'})}>General Info</a>
                      <a style={{ cursor: "pointer" }} onClick={e => (document.getElementsByName('staff-roles')[0]).scrollIntoView({behavior: 'smooth'})} className='nav-bar-modal'>Support Staff</a>
                      <a style={{ cursor: "pointer" }} onClick={e => (document.getElementsByName('location-specifics')[0]).scrollIntoView({behavior: 'smooth'})} className='nav-bar-modal'>Location Specifics</a>
                  </div>
                  <Divider orientation="vertical" variant="fullLength"/>
                  <div className="info-body">
                      <pre id='ModalText' className='more-information' dangerouslySetInnerHTML={renderBoldWords()} style={{display: isLoading ? 'none' : 'inline'}}>
                          {/* {setSortArrayformattedResponse} */}
                      </pre>
                      <div className="loading-page-entry" style={{display: isLoading ? 'flex' : 'none'}}>
                        <LoadingIndicator
                          size="lg"
                          variant="page"
                        />
                      </div>
                  </div>
              </div>
          </ModalBody>
        </React.Fragment>
      </Modal>
    </>
  );
};

export default MapModal;



/*

This code is a React component that renders a modal window with detailed information about a given location. The component imports several components from the cfa-react-components library, as well as the useState and useEffect hooks from React. 

The useDidMountEffect hook is defined to run a function when the component mounts, and then again whenever the dependencies change. This hook is used to open the modal window when showModal is true, and to get the info for the location when the locationToDisplay changes. 

The renderBoldWords function is used to format the response from the API call into a readable format. It uses regular expressions to remove unnecessary characters and replace them with bolded words. 

Finally, the MapModal component renders the modal window, which contains a header, body, and footer. The body contains a menu of sections, a divider, and an area to display the formatted response. If the response is still loading, a loading indicator is displayed instead.

*/